import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 998.000000 997.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,997.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M4745 9574 c-389 -37 -619 -76 -907 -154 -1285 -350 -2353 -1217
-2956 -2402 -664 -1305 -679 -2844 -39 -4169 378 -785 978 -1454 1723 -1925
44 -28 107 -66 140 -84 269 -151 377 -203 605 -295 1225 -495 2598 -448 3794
129 484 233 899 531 1287 923 181 183 270 285 412 473 527 699 829 1475 928
2385 17 159 17 698 0 875 -83 852 -396 1669 -903 2356 -303 410 -706 797
-1134 1086 -637 431 -1325 686 -2115 783 -127 16 -725 29 -835 19z m795 -268
c614 -72 1195 -260 1715 -554 136 -77 444 -286 568 -385 772 -619 1310 -1458
1546 -2411 92 -368 125 -653 125 -1066 0 -325 -10 -449 -59 -745 -199 -1189
-897 -2263 -1910 -2940 -596 -398 -1219 -626 -1970 -722 -219 -28 -799 -25
-1028 5 -534 70 -955 197 -1433 432 -515 253 -947 581 -1344 1021 -675 747
-1078 1742 -1119 2762 -45 1095 288 2106 980 2980 129 164 452 490 622 630
686 564 1543 920 2397 996 69 6 143 13 165 15 97 9 618 -4 745 -18z"
          />
          <path
            d="M3083 7503 c-12 -32 -45 -119 -73 -193 -28 -74 -57 -151 -64 -170 -7
-19 -73 -192 -146 -385 -73 -192 -174 -460 -225 -595 -51 -135 -132 -348 -180
-475 -48 -126 -118 -313 -157 -415 -179 -473 -320 -846 -363 -960 -26 -69 -62
-163 -80 -210 -18 -47 -36 -95 -39 -107 l-7 -23 139 0 139 0 28 83 c15 45 31
87 35 92 4 6 23 55 43 110 20 55 57 154 82 220 25 66 82 217 126 335 44 118
92 244 105 280 37 97 52 136 69 180 8 22 22 58 30 80 28 76 48 125 61 157 8
17 14 36 14 42 0 6 6 25 14 43 30 69 44 105 61 153 10 28 24 64 31 82 8 17 29
73 48 125 19 51 45 122 59 158 15 36 67 173 117 305 50 132 93 247 97 255 3 8
7 20 8 25 2 6 32 88 69 183 36 94 66 175 66 178 0 3 9 24 19 47 10 23 33 80
50 127 16 47 51 139 76 205 25 66 45 121 45 123 0 1 -62 2 -138 2 l-138 0 -21
-57z"
          />
          <path
            d="M3640 6758 c-12 -40 -40 -128 -61 -195 -22 -67 -39 -134 -39 -148 0
-36 186 -587 265 -785 7 -19 77 -213 154 -430 78 -217 208 -582 289 -810 l149
-415 136 -3 c150 -3 153 -2 123 56 -7 15 -27 68 -44 117 -33 99 -99 285 -156
440 -40 108 -116 324 -120 340 -2 6 -6 17 -9 25 -4 8 -32 85 -62 170 -31 85
-61 169 -68 185 -6 17 -12 35 -13 40 -1 6 -39 111 -84 235 -144 394 -241 673
-276 790 -46 157 -148 460 -155 460 -4 0 -17 -33 -29 -72z"
          />
          <path
            d="M4840 6631 c0 -6 4 -12 9 -15 5 -3 11 -16 14 -28 3 -13 14 -45 25
-73 10 -27 63 -165 115 -305 53 -140 115 -302 137 -360 23 -58 43 -109 44
-115 2 -5 6 -17 9 -25 22 -54 206 -542 216 -572 7 -21 17 -40 22 -43 5 -4 9
-13 9 -22 0 -8 6 -29 13 -46 28 -64 77 -194 77 -201 0 -4 6 -22 14 -39 8 -18
29 -70 46 -117 18 -47 44 -114 58 -150 13 -36 40 -105 58 -155 18 -49 54 -144
79 -210 25 -66 61 -160 80 -210 19 -49 49 -130 68 -180 19 -49 55 -144 80
-210 109 -286 138 -361 146 -380 12 -25 35 -97 36 -110 0 -6 107 -11 296 -13
l295 -2 48 127 c27 71 78 205 113 298 36 94 78 204 93 245 24 65 211 561 482
1275 44 116 137 363 208 550 188 496 312 824 364 960 25 66 46 123 46 128 0 4
-142 6 -316 5 l-315 -3 -30 -75 c-16 -41 -29 -80 -29 -87 0 -7 -7 -27 -15 -45
-13 -31 -67 -179 -187 -513 -28 -77 -56 -153 -64 -170 -7 -16 -16 -43 -20 -59
-4 -16 -13 -41 -20 -55 -8 -14 -14 -30 -14 -35 0 -10 -83 -241 -101 -281 -5
-11 -22 -58 -39 -105 -17 -47 -33 -92 -37 -100 -3 -8 -7 -19 -9 -25 -4 -16
-80 -227 -93 -258 -6 -16 -11 -34 -11 -41 0 -6 -4 -16 -9 -21 -5 -6 -16 -32
-24 -60 -8 -27 -34 -99 -57 -160 -53 -139 -154 -437 -211 -622 -24 -79 -46
-137 -50 -130 -9 16 -84 241 -149 447 -29 91 -140 410 -247 710 -107 300 -214
599 -238 665 -23 66 -111 311 -195 545 -84 234 -157 437 -162 453 l-10 27
-324 0 c-196 0 -324 -4 -324 -9z"
          />
          <path
            d="M2820 3894 c-143 -24 -227 -61 -286 -127 -143 -158 -146 -472 -7
-628 93 -102 177 -134 342 -127 81 4 118 10 156 27 82 37 85 42 85 144 l0 89
-39 -35 c-53 -48 -100 -62 -206 -62 -107 0 -158 21 -205 87 -40 54 -53 106
-53 203 0 198 113 291 322 264 48 -6 89 -18 117 -34 l44 -26 0 95 0 95 -51 15
c-53 16 -177 27 -219 20z"
          />
          <path
            d="M3190 3809 l0 -76 105 5 105 5 0 -362 0 -361 90 0 90 0 0 362 0 361
105 -6 105 -6 0 77 0 77 -300 0 -300 0 0 -76z"
          />
          <path
            d="M4378 3882 c-49 -1 -88 -4 -88 -6 0 -3 16 -48 95 -271 105 -295 185
-538 185 -562 0 -22 3 -23 83 -23 l83 0 13 62 c7 33 68 223 137 421 68 198
124 364 124 368 0 5 -40 9 -89 9 l-88 0 -51 -172 c-28 -95 -65 -224 -83 -285
-18 -62 -35 -110 -38 -107 -3 3 -49 132 -101 287 l-95 282 -87 -3z"
          />
          <path
            d="M5150 3453 l0 -433 240 0 240 0 0 43 c0 23 3 59 6 79 l7 37 -164 3
-163 3 1 30 c0 17 1 67 2 113 l1 82 121 0 122 0 -7 31 c-3 17 -6 51 -6 75 l0
44 -117 0 -118 0 3 86 4 86 143 -4 143 -4 6 59 c12 114 39 102 -229 102 l-235
0 0 -432z"
          />
          <path
            d="M1765 3459 c-82 -231 -152 -425 -153 -430 -3 -6 33 -9 88 -7 l93 3
29 93 29 92 147 0 147 0 29 -95 29 -95 97 0 98 0 -95 258 c-52 141 -122 335
-156 429 l-62 173 -85 0 -85 0 -150 -421z m279 57 c25 -77 46 -143 46 -148 0
-4 -43 -8 -96 -8 -73 0 -95 3 -91 13 2 6 24 74 47 149 24 76 44 137 45 135 2
-1 23 -65 49 -141z"
          />
          <path d="M3950 3450 l0 -430 90 0 90 0 0 430 0 430 -90 0 -90 0 0 -430z" />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
